import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Autocomplete from "@mui/material/Autocomplete";

import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import {
  _add_category,
  _update_category,
  _list_parent_category,
} from "../../DAL/category/category";
import GeneralCkeditor from "../GeneralComponents/GeneralCkeditor";

export default function AddOrUpdateCategory({
  onCloseDrawer,
  formType,
  setCategoryData,
  categoryData,
  selectedRow,
  apiFunction,
}) {
  const [previews, setPreviews] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState();
  const [inputs, setInputs] = useState({
    title: "",
    parent_category: "",
    status: true,
    short_description: "",
    detailed_description: "",
    is_show_on_website: false,
    show_in: "both",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const get_parent_category_list = async () => {
    const response = await _list_parent_category();
    if (response.code === 200) {
      setCategoriesData(response.category);
      if (formType == "EDIT") {
        let selecte_product = response.category.find(
          (product) => product._id === selectedRow.parent_category
        );
        setSelectedParent(selecte_product);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });

      setIsLoading(false);
    }
  };

  //Adding Category
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.short_description);
    formData.append("detailed_description", inputs.detailed_description);
    formData.append("status", inputs.status);
    if (image) {
      formData.append("image", image);
    }

    if (selectedParent != null) {
      formData.append("parent_category", selectedParent._id);
    } else {
      formData.append("is_show_on_website", inputs.is_show_on_website);
      if (inputs.is_show_on_website) {
        formData.append("show_in", inputs.show_in);
      }
    }

    setIsLoading(true);
    const response =
      formType === "ADD"
        ? await _add_category(formData)
        : await _update_category(inputs._id, formData);
    if (response.code === 200) {
      let response_object = response.category;
      response_object.category_image = {
        src: s3baseUrl + response.category.image,
        alt: response.category.title,
      };
      response_object.less_description = {
        text: response.category.short_description,
        show_full_text: false,
        limit: 50,
      };

      if (formType === "ADD") {
        setCategoryData((prev) => [response_object, ...prev]);
      } else {
        const newState = categoryData.map((obj) => {
          if (obj._id === inputs._id) {
            return response_object;
          }
          return obj;
        });
        setCategoryData(newState);
      }
      apiFunction();
      onCloseDrawer();

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_parent_category_list();
    if (formType == "EDIT") {
      setInputs(selectedRow);
      if (selectedRow.image) {
        setPreviews(s3baseUrl + selectedRow.image);
      }
      // setPreviews(s3baseUrl + selectedRow.image);
      console.log(selectedRow, "selectedRow");
    }
  }, [formType]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="title"
              value={inputs?.title}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <div className="col-12">
          <FormControl fullWidth>
            <Autocomplete
              id="category-autocomplete"
              options={categoriesData}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) => option._id === value._id}
              value={selectedParent}
              onChange={(event, newValue) => {
                setSelectedParent(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Parent Category"
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
              )}
            />
          </FormControl>
        </div>
        {!selectedParent && (
          <>
            <div className="col-12">
              <FormControl fullWidth sx={{ mb: 2 }} required>
                <InputLabel id="demo-simple-select-label">
                  Show On Website Menu
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_show_on_website"
                  value={inputs.is_show_on_website}
                  label="Show On Website Menu"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inputs.is_show_on_website && (
              <div className="col-12">
                <FormControl fullWidth sx={{ mb: 2 }} required>
                  <InputLabel id="demo-simple-select-label">Show In</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="show_in"
                    value={inputs.show_in}
                    label="Show In"
                    onChange={handleChange}
                  >
                    <MenuItem value="header">Header</MenuItem>
                    <MenuItem value="footer">Footer</MenuItem>
                    <MenuItem value="both">Both</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </>
        )}
        <div className="col-12">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              required
              label="Status"
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <p className="mt-2 mb-0">Upload Image (1000x670) *</p>
        <div className="col-md-12 mt-2 d-flex">
          {previews && (
            <span className="preview mt-2">
              <span onClick={handleRemove}>x</span>
              <img src={previews} />
            </span>
          )}
          {previews == "" && (
            <span className="upload-button mt-2">
              <input
                color="primary"
                accept="image/*"
                type="file"
                id="icon-button-file"
                style={{ display: "none" }}
                name="affirmationImage"
                onChange={handleUpload}
              />
              <label htmlFor="icon-button-file">
                <CloudUploadIcon />
              </label>
            </span>
          )}
        </div>
        <div className="col-12">
          <TextField
            id="outlined-multiline-static"
            label="Short Description"
            multiline
            rows={5}
            name="short_description"
            value={inputs.short_description}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            style={{ width: "100%" }}
            className="mt-3"
          />
        </div>
        <div className="col-12 mt-3">
          <h4 style={{ fontSize: "1rem" }}>Detail Description </h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            required
            name="detailed_description"
            editorHeight={250}
          />
        </div>
        <div className="text-end mt-3 mb-3">
          <button className="small-contained-button" disabled={isLoading}>
            {isLoading
              ? formType === "ADD"
                ? "Saving..."
                : "Updating..."
              : formType === "ADD"
              ? "Save"
              : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
