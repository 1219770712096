export const ORDER_STATUSES = [
  {
    status: "pending",
    color: "#ff9800",
  },
  {
    status: "ready_to_ship",
    color: "#03a9f4",
  },
  {
    status: "shipped",
    color: "#0288d1",
  },
  {
    status: "delivered",
    color: "#4caf50",
  },
  {
    status: "cancelled",
    color: "#cccccc",
  },
  {
    status: "returned",
    color: "#f01e2c",
  },
];

export const show_proper_words = (text) => {
  let replace_string = "";
  if (text) {
    // Replace hyphens and underscores with spaces
    replace_string = text.replace(/[-_]/g, " ");
    // Capitalize the first letter of every word
    replace_string = replace_string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return replace_string;
};

export let find_order_color = (status) => {
  let order_status = ORDER_STATUSES.find(
    (ORDER_STATUS) => ORDER_STATUS.status == status
  );
  return order_status.color;
};
