import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import { Button, CircularProgress, TextField, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { _general_Image_upload } from "../../DAL/general_image_upload/general_Image_upload";
import {
  _get_about_us_settings_admin,
  _add_about_us_settings_admin,
  _add_image,
  _del_image,
  get_page_info_setting_api,
  add_page_info_setting_api,
} from "../../DAL/website_setting/about_us_setting";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

function InformationSetting() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { userInfo, privilegeAccess } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    page_heading: "",
    top_description: "",
    middle_description: "",
    bottom_description: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_about_us_setting_admin_list = async () => {
    setIsLoading(true);
    const result = await get_page_info_setting_api();
    if (result.code === 200) {
      setInputs({
        ...inputs,
        ...result.page_info_settings,
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userInfo.type == 2) {
      if (
        privilegeAccess?.add_informative_setting === true ||
        privilegeAccess?.edit_informative_setting === true
      ) {
        const objectData = {
          ...inputs,
        };
        setIsButtonLoading(true);
        const result = await _add_about_us_settings_admin({
          page_info_settings: objectData,
        });

        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      const objectData = {
        ...inputs,
      };
      setIsButtonLoading(true);
      const result = await add_page_info_setting_api({
        page_info_settings: objectData,
      });

      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };

  useEffect(() => {
    get_about_us_setting_admin_list();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  //===================================================================

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <h3 className="my-3">Informative Setting</h3>
          </div>
        </div>
        {userInfo.type == 2 && !privilegeAccess?.view_informative_setting ? (
          <NoAccessData />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row">
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  required
                  label="Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="page_heading"
                  onChange={handleChange}
                  value={inputs?.page_heading}
                />
              </div>
            </div>
            {/*  Top description*/}
            <div className="row">
              <div className="col-12 mb-4">
                <p className="home_page_header">Top Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="top_description"
                  editorHeight={350}
                />
              </div>
            </div>
            <div className="row">
              {/*  Middle  description*/}
              <div className="col-12 mb-4">
                <p className="home_page_header">Middle Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="middle_description"
                  editorHeight={350}
                />
              </div>
              {/* Bottom description*/}
              <div className="col-12 mb-4">
                <p className="home_page_header">Bottom Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="bottom_description"
                  editorHeight={350}
                />
              </div>
            </div>

            <div className="col-12 mt-3 text-end">
              <Tooltip
                title={
                  userInfo.type == 2 &&
                  (privilegeAccess?.edit_informative_setting ||
                    privilegeAccess?.add_informative_setting) === false
                    ? "You have no access to perform this action"
                    : ""
                }
                placement="right-end"
                arrow
              >
                {isButtonLoading ? (
                  <LoadingButton loading variant="contained">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                )}
              </Tooltip>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default InformationSetting;
