import React, { useEffect, useState } from "react";
import CustomMUITable from "../../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _delete_product_API,
  _list_product_API,
} from "../../../DAL/product/product";
import { CircularProgress } from "@mui/material";
import CustomConfirmation from "../../../components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  _delete_website_page,
  _list_website_page,
} from "../../../DAL/website_setting/about_us_setting";
import { website_url } from "../../../config/config";
import { _delete_attribute } from "../../../DAL/AttributeVarients/AttributeVarients";

export default function ManageWebsiteList() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleEdit = (value) => {
    navigate(`/website-pages/edit-page/${value.page_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };
  const handleNavigate = () => {
    navigate(`/website-pages/add-page`);
  };

  const get_pages_list = async () => {
    setIsLoading(true);
    let search_text = localStorage.getItem("page_search_text");
    let search_keyword = "";
    if (search_text) {
      search_keyword = search_text;
      setSearchText(search_keyword);
    } else {
      search_keyword = searchText;
    }

    const response = await _list_website_page(
      page,
      rowsPerPage,
      search_keyword
    );
    if (response.code === 200) {
      setListData(response?.website_pages);
      setTotalCount(response?.total_count);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    get_pages_list();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const response = await _delete_website_page(selectedRow.page_slug);
    if (response.code == 200) {
      setListData((prevData) =>
        prevData.filter((item) => item._id !== selectedRow._id)
      );
      enqueueSnackbar(response.message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "website_page_title", label: "Page Title" },
    {
      id: "URL ",
      label: "URL",
      renderData: (row) => {
        return (
          <a
            style={{
              textDecoration: "none",
            }}
            href={`${website_url}/${row.page_slug}`}
            target="_blank"
          >
            {row.status == true ? "Preview" : ""}
          </a>
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
    { id: "action", label: "Action", type: "action" },
  ];

  useEffect(() => {
    get_pages_list();
  }, [page, rowsPerPage]);

  useEffect(() => {
    localStorage.setItem("page_search_text", searchText);
  }, [searchText]);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Page?"}
        handleAgree={handleDelete}
      />

      <div className="row mt-2">
        <div className="col-6">
          <h3>Website Pages</h3>
        </div>
        <div className="col-6 text-end">
          <button className="small-contained-button" onClick={handleNavigate}>
            Add Page
          </button>
        </div>

        <div className="col-12 mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={listData}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
          />
        </div>
      </div>
    </div>
  );
}
