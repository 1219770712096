import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_about_us_settings_admin = async (data) => {
  const requestObj = {
    path: `api/website_settings/get_about_us_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_page_info_setting_api = async (data) => {
  const requestObj = {
    path: `api/website_settings/get_page_info_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_about_us_settings_admin = async (data) => {
  const requestObj = {
    path: `api/website_settings/add_about_us_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_page_info_setting_api = async (data) => {
  const requestObj = {
    path: `api/website_settings/add_page_info_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_image = async (postData) => {
  const requestObj = {
    path: `api/app_api/upload_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _del_image = async (postData) => {
  const requestObj = {
    path: `api/init/delete_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _get_list_template_configuration = async () => {
  const requestObj = {
    path: `api/template_configuration/list_template_configuration`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_template_configuration = async (id) => {
  const requestObj = {
    path: `api/website_page/get_website_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_website_page = async (page, limit, search) => {
  const requestObj = {
    path: `api/website_page/list_website_page?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_website_page = async (id) => {
  const requestObj = {
    path: `api/website_page/delete_website_page/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_website_page = async (data) => {
  const requestObj = {
    path: `api/website_page/add_website_page`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_website_page = async (data, id) => {
  const requestObj = {
    path: `api/website_page/update_website_page/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
