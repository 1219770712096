import React, { useEffect, useState } from "react";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { filter_shipment_history } from "../../DAL/product/product";
import moment from "moment";
import { show_proper_words } from "../../utils/constant";
import { _list_shipment } from "../../DAL/Shipment/shipment";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import HistoryFilter from "./HistoryFilter";
import FilteredChip from "../../components/FilteredChip";

export default function DeadHistory({ type }) {
  const { state } = useLocation();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  let fish_name = state?.reviewValues;
  const [historyData, setHistoryData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [drawerState, setDrawerState] = useState(false);
  const EMPTY_FILTER = {
    search: "",
    shipment_id: null,
    product_id: null,
    variation_id: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const navigate = useNavigate();

  const get_list_product_review = async (filterData) => {
    setIsLoading(true);

    let postData = { ...filterData };
    if (postData.shipment_id) {
      postData.shipment_id = filterData.shipment_id._id;
    }
    if (postData.product_id) {
      postData.product_id = filterData.product_id._id;
    }
    if (postData.variation_id) {
      postData.variation_id = filterData.variation_id.variation_id;
    }

    handleCloseFilterDrawer();
    const response = await filter_shipment_history(page, rowsPerPage, postData);
    if (response.code === 200) {
      let chipData = { ...filterData };

      if (chipData.shipment_id) {
        chipData.shipment_id = {
          ...chipData.shipment_id,
          chip_label: chipData.shipment_id.shipment_title,
          chip_value: chipData.shipment_id._id,
        };
      }
      if (chipData.product_id) {
        chipData.product_id = {
          ...chipData.product_id,
          chip_label:
            chipData.product_id.common_name +
            " " +
            chipData.product_id.scientific_name,
          chip_value: chipData.product_id._id,
        };
      }
      if (chipData.variation_id) {
        chipData.variation_id = {
          ...chipData.variation_id,
          chip_label:
            chipData.variation_id.attribute_name +
            " | " +
            chipData.variation_id.item_name,
          chip_value: chipData.variation_id.variation_id,
        };
      }

      if (chipData.search) {
        delete chipData.search;
      }
      setUpdateFilterData(chipData);
      let data = response.shipment_histories.map((variation) => {
        let variationName1 = "";
        let variationName2 = "";
        if (
          Array.isArray(variation?.variation_attributes_items) &&
          variation.variation_attributes_items.length > 0
        ) {
          if (variation.variation_attributes_items[0]) {
            variationName1 =
              variation.variation_attributes_items[0]?.attribute_name +
                " | " +
                variation.variation_attributes_items[0]?.item_name || "";
          }
          if (variation.variation_attributes_items[1]) {
            variationName2 =
              variation.variation_attributes_items[1]?.attribute_name +
                " | " +
                variation.variation_attributes_items[1]?.item_name || "";
          }
        }
        return {
          ...variation,
          attribute1: variationName1,
          attribute2: variationName2,
        };
      });
      setHistoryData(data);
      setTotalCount(response.total_shipment_histories);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    get_list_product_review(data);
    setUpdateFilterData(data);
    setFilterData(data);
    localStorage.setItem("history_filter", JSON.stringify(data));
  };

  const searchFunction = () => {
    setPage(0);
    filterData.search = searchText;
    get_list_product_review(filterData);
    localStorage.setItem("history_filter", JSON.stringify(filterData));
  };

  const handleClearFilter = () => {
    setPage(0);
    get_list_product_review(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("history_filter");
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "shipment",
      label: "Shipment",
      renderData: (row) => {
        return <> {row?.shipment?.shipment_title}</>;
      },
    },
    {
      id: "product",
      label: "Product",
      renderData: (row) => {
        return (
          <>{row?.product?.common_name + " " + row?.product?.scientific_name}</>
        );
      },
    },
    { id: "attribute1", label: "Attribute 1" },
    { id: "attribute2", label: "Attribute 2" },
    { id: "dead_count", label: "Dead Count" },
    {
      id: "date",
      label: "Date",
      renderData: (row) => {
        return <>{moment(row.date).format("DD-MM-YYYY")}</>;
      },
    },
    {
      id: "dead_type",
      label: "Dead Type",
      renderData: (row) => {
        return <>{show_proper_words(row.dead_type)}</>;
      },
    },
  ];

  const handleOpenDraweradd = () => {
    setDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setDrawerState(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBack = () => {
    navigate(-1);
    localStorage.removeItem("history_filter");
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("history_filter");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search) {
        setSearchText(filter_data.search);
      }
    } else {
      if (fish_name?._id && type === "shipment") {
        filter_data.shipment_id = fish_name;
      }
      if (fish_name?._id && type === "products") {
        filter_data.product_id = {
          ...fish_name,
          full_name: fish_name?.common_name + " " + fish_name?.scientific_name,
        };
      }
      if (fish_name?._id && type === "variations") {
        const attributeItem =
          Array.isArray(fish_name?.attributes_items) &&
          fish_name?.attributes_items.length > 0
            ? fish_name?.attributes_items[0]
            : null;

        filter_data.product_id = {
          common_name: fish_name?.common_name,
          scientific_name: fish_name?.scientific_name,
          full_name: fish_name?.common_name + " " + fish_name?.scientific_name,
          _id: fish_name.product_id,
        };

        filter_data.variation_id = {
          ...fish_name,
          variation_id: fish_name?._id,
          attribute_name: attributeItem?.attribute_name || "",
          item_name: attributeItem?.item_name || "",
          full_name: attributeItem
            ? attributeItem.attribute_name + " | " + attributeItem.item_name
            : "",
        };
      }
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    get_list_product_review(filter_data);
  }, [page, rowsPerPage]);

  useEffect(() => {
    localStorage.removeItem("history_filter");
  }, [location.pathname]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-8">
            <IconButton onClick={handleBack} className="icon-button">
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
            <span className="page_title">
              {type === "shipment" ? (
                <>{fish_name?.shipment_title}</>
              ) : type === "variations" ? (
                <>{fish_name?.attribute1}</>
              ) : (
                <>{fish_name?.common_name + " " + fish_name?.scientific_name}</>
              )}
            </span>
          </div>
          <div className="col-4 text-end">
            <Button variant="contained" onClick={handleOpenDraweradd}>
              Filter
            </Button>
          </div>
        </div>
        <div className="row mt-3">
          <FilteredChip
            data={updateFilterData}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={historyData}
              className="card-with-background"
              table_title="Dead Count History"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        // onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <HistoryFilter
            handleSelectOther={handleSelectOther}
            filterData={filterData}
            setFilterData={setFilterData}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
