import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import {
  list_active_shipment_api,
  list_products_shippments_variations,
} from "../../DAL/Shipment/shipment";
import { useSnackbar } from "notistack";
import MUIAutocomplete from "../../components/MUIAutocomplete";

export default function HistoryFilter({
  filterData,
  searchFunction,
  handleSelectOther,
  handleClearFilter,
}) {
  const [searchText, setSerchText] = useState("");
  const [searchText1, setSerchText1] = useState("");
  const [sipmentData, setsipmentData] = useState([]);
  const [sipmentData1, setsipmentData1] = useState([]);
  const [sipmentData2, setsipmentData2] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const get_shipment_list = async () => {
    const postData = {
      search: searchText,
    };
    const response = await list_active_shipment_api(postData);
    if (response.code === 200) {
      let filter_data = response?.shipments?.filter((item) => {
        return item.shipment_title !== "";
      });
      setsipmentData(filter_data);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const get_shipment_list_2 = async () => {
    const postData = {
      search: searchText1,
      type: "product",
    };
    const response = await list_products_shippments_variations(postData);
    if (response.code === 200) {
      let data = response.data.map((item) => {
        let full_name = item.common_name + " " + item.scientific_name;
        return {
          ...item,
          full_name,
        };
      });
      setsipmentData1(data);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleChangeOther = (value) => {
    if (value.variation.length > 0) {
      const attributes = value.variation
        .map((item) => item.attributes_items)
        .flat();
      if (attributes.length > 0) {
        let data_new = attributes.map((item, i) => {
          let full_name = item.attribute_name + " | " + item.item_name;

          let variation = value.variation.find((v) =>
            v.attributes_items.some((attr) => attr.item_name === item.item_name)
          );
          return {
            ...item,
            full_name,
            variation_id: variation ? variation._id : null,
          };
        });
        setsipmentData2(data_new);
      }
    }
  };

  useEffect(() => {
    get_shipment_list_2();
  }, [searchText1]);

  useEffect(() => {
    get_shipment_list();
  }, [searchText]);

  return (
    <div className="container-fluid new-memories transaction-filter">
      <div className="mt-4">
        <MUIAutocomplete
          inputLabel="Shipment"
          selectedOption={filterData.shipment_id}
          setSelectedOption={(e) => {
            handleSelectOther("shipment_id", e);
          }}
          optionsList={sipmentData}
          setCustomSearch={setSerchText}
          name="shipment_title"
        />
      </div>
      <div className="mt-3">
        <MUIAutocomplete
          inputLabel="Product"
          selectedOption={filterData.product_id}
          setSelectedOption={(e) => {
            handleSelectOther("product_id", e);
            handleChangeOther(e);
          }}
          optionsList={sipmentData1}
          setCustomSearch={setSerchText1}
          name="full_name"
        />
      </div>
      {filterData.product_id && (
        <div className="mt-3">
          <MUIAutocomplete
            inputLabel="Variation"
            selectedOption={filterData.variation_id}
            setSelectedOption={(e) => {
              handleSelectOther("variation_id", e);
            }}
            optionsList={sipmentData2}
            name="full_name"
          />
        </div>
      )}
      <div className="text-end mt-3">
        <Button
          size="large"
          type="submit"
          variant="contained"
          className="me-3"
          onClick={searchFunction}
        >
          Filter
        </Button>
        <Button
          size="large"
          type="submit"
          variant="outlined"
          onClick={handleClearFilter}
        >
          Clear All
        </Button>
      </div>
    </div>
  );
}
