import React, { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import {
  Button,
  Input,
  FormHelperText,
  CircularProgress,
  Tooltip,
  TextField,
} from "@mui/material";
import { _general_Image_upload } from "../../DAL/general_image_upload/general_Image_upload";
import { s3baseUrl } from "../../config/config";
import { LoadingButton } from "@mui/lab";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
import {
  _add_product_detail_settings,
  _get_product_details_settings,
} from "../../DAL/website_setting/product_details_setting";
function ProductDetailSetting() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [previewsSafePayment, setPreviewsSafePayment] = useState();
  const [previewsConfidence, setPreviewsConfidence] = useState();
  const [previewsWorldwideDelivery, setPreviewsWorldwideDelivery] = useState();
  const [previewsHotline, setPreviewsHotline] = useState();
  const [isSafePaymentImageLoading, setIsSafePaymentImageLoading] =
    useState(true);
  const [isConfidenceLoading, setIsConfidenceLoading] = useState(true);
  const [isWorldwideDeliveryImageLoading, setIsWorldwideDeliveryImageLoading] =
    useState(true);
  const [isHotlineImageLoading, setIsHotlineImageLoading] = useState(true);
  const { userInfo, privilegeAccess } = useContentSetting();

  let [inputs, setInputs] = React.useState({
    safe_payment_image: "",
    confidence_image: "",
    worldwide_delivery_image: "",
    hotline_image: "",
    page_heading: "",
    safe_payment: "",
    confidence: "",
    worldwide_delivery: "",
    hotline: "",
    product_details: "",
    shipping_and_return: "",
  });

  const fileChangedHandlerSafePayment = async (event) => {
    if (event.target.files[0]) {
      setIsSafePaymentImageLoading(true);
      const result = await _general_Image_upload(
        event.target.files[0],
        "50",
        "50"
      );
      if (result.code === 200) {
        setPreviewsSafePayment(result.path);
        setInputs({
          ...inputs,
          safe_payment_image: result.path,
        });
      }
      setIsSafePaymentImageLoading(false);
    }
  };

  const fileChangedHandlerConfidence = async (event) => {
    if (event.target.files[0]) {
      setIsConfidenceLoading(true);
      const result = await _general_Image_upload(
        event.target.files[0],
        "50",
        "50"
      );
      if (result.code === 200) {
        setPreviewsConfidence(result.path);
        setInputs({
          ...inputs,
          confidence_image: result.path,
        });
      }
      setIsConfidenceLoading(false);
    }
  };
  const fileChangedHandlerWorldwideDelivery = async (event) => {
    if (event.target.files[0]) {
      setIsWorldwideDeliveryImageLoading(true);
      const result = await _general_Image_upload(
        event.target.files[0],
        "50",
        "50"
      );
      if (result.code === 200) {
        setPreviewsWorldwideDelivery(result.path);
        setInputs({
          ...inputs,
          worldwide_delivery_image: result.path,
        });
      }
      setIsWorldwideDeliveryImageLoading(false);
    }
  };
  const fileChangedHandlerHotline = async (event) => {
    if (event.target.files[0]) {
      setIsHotlineImageLoading(true);
      const result = await _general_Image_upload(
        event.target.files[0],
        "50",
        "50"
      );
      if (result.code === 200) {
        setPreviewsHotline(result.path);
        setInputs({
          ...inputs,
          hotline_image: result.path,
        });
      }
      setIsHotlineImageLoading(false);
    }
  };

  const product_detail_setting_list = async () => {
    setIsLoading(true);
    setIsSafePaymentImageLoading(true);
    setIsConfidenceLoading(true);
    setIsWorldwideDeliveryImageLoading(true);
    setIsHotlineImageLoading(true);
    const result = await _get_product_details_settings();
    if (result.code === 200) {
      setInputs(result.product_details_settings);

      if (result.product_details_settings.safe_payment_image) {
        setPreviewsSafePayment(
          result.product_details_settings.safe_payment_image
        );
      }
      if (result.product_details_settings.confidence_image) {
        setPreviewsConfidence(result.product_details_settings.confidence_image);
      }
      if (result.product_details_settings.worldwide_delivery_image) {
        setPreviewsWorldwideDelivery(
          result.product_details_settings.worldwide_delivery_image
        );
      }
      if (result.product_details_settings.hotline_image) {
        setPreviewsHotline(result.product_details_settings.hotline_image);
      }
      setIsLoading(false);
      setIsSafePaymentImageLoading(false);
      setIsConfidenceLoading(false);
      setIsWorldwideDeliveryImageLoading(false);
      setIsHotlineImageLoading(false);
    } else {
      setIsLoading(false);
      setIsSafePaymentImageLoading(false);
      setIsConfidenceLoading(false);
      setIsWorldwideDeliveryImageLoading(false);
      setIsHotlineImageLoading(false);
    }
  };

  useEffect(() => {
    product_detail_setting_list();
  }, []);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userInfo.type == 2) {
      if (
        privilegeAccess.add_product_detail_setting === true ||
        privilegeAccess.edit_product_detail_setting === true
      ) {
        setIsButtonLoading(true);
        const result = await _add_product_detail_settings({
          product_details_settings: inputs,
        });

        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      setIsButtonLoading(true);
      const result = await _add_product_detail_settings({
        product_details_settings: inputs,
      });

      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }
  //===================================================================

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <h3 className="my-3">Product Detail Setting</h3>
          </div>
        </div>
        {userInfo.type == 2 && !privilegeAccess.view_product_detail_setting ? (
          <NoAccessData />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row">
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  required
                  label="Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="page_heading"
                  onChange={handleChange}
                  value={inputs?.page_heading}
                />
              </div>
            </div>
            <div className="row">
              {/* Safe Payment Image */}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="general_setting_page_header">
                      Safe Payment Image
                    </p>
                    <FormHelperText className="pt-0">
                      Recommended Size(50 X 50) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isSafePaymentImageLoading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img
                          src={s3baseUrl + previewsSafePayment}
                          height={50}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file-safe-payment">
                      <Input
                        accept="image/*"
                        id="contained-button-file-safe-payment"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="safe_payment_image"
                        onChange={fileChangedHandlerSafePayment}
                      />
                      <Button
                        id="contained-button-file-safe-payment"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              {/* Confidence Image */}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="general_setting_page_header">
                      Confidence Image
                    </p>
                    <FormHelperText className="pt-0">
                      Recommended Size(50 X 50) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isConfidenceLoading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img src={s3baseUrl + previewsConfidence} height={50} />
                      )}
                    </div>
                  </div>

                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file-confidence">
                      <Input
                        accept="image/*"
                        id="contained-button-file-confidence"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="confidence_image"
                        onChange={fileChangedHandlerConfidence}
                      />
                      <Button
                        id="contained-button-file-confidence"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>

              {/* Worldwide Delivery Image */}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="general_setting_page_header">
                      Worldwide Delivery Image
                    </p>
                    <FormHelperText className="pt-0">
                      Recommended Size(50 X 50) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isWorldwideDeliveryImageLoading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img
                          src={s3baseUrl + previewsWorldwideDelivery}
                          height={50}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file-worldwide-delivery">
                      <Input
                        accept="image/*"
                        id="contained-button-file-worldwide-delivery"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="worldwide_delivery_image"
                        onChange={fileChangedHandlerWorldwideDelivery}
                      />
                      <Button
                        id="contained-button-file-worldwide-delivery"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>

              {/* Hotline Image */}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="general_setting_page_header">Hotline Image</p>
                    <FormHelperText className="pt-0">
                      Recommended Size(50 X 50) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isHotlineImageLoading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img src={s3baseUrl + previewsHotline} height={50} />
                      )}
                    </div>
                  </div>

                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file-hotline">
                      <Input
                        accept="image/*"
                        id="contained-button-file-hotline"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="hotline_image"
                        onChange={fileChangedHandlerHotline}
                      />
                      <Button
                        id="contained-button-file-hotline"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Safe Payment</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="safe_payment"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Confidence</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="confidence"
                editorHeight={350}
              />
            </div>
            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Worldwide Delivery</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="worldwide_delivery"
                editorHeight={350}
              />
            </div>
            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Hotline</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="hotline"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Product Details</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="product_details"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Shipping & Returns</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="shipping_and_return"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mt-3 text-end">
              <Tooltip
                title={
                  userInfo.type == 2 &&
                  (privilegeAccess.edit_product_detail_setting ||
                    privilegeAccess.add_product_detail_setting) === false
                    ? "You have no access to perform this action"
                    : ""
                }
                placement="right-end"
                arrow
              >
                {isButtonLoading ? (
                  <LoadingButton loading variant="contained">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    //    style={{ textTransform: 'capitalize' }}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </Tooltip>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default ProductDetailSetting;
