import React, { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { _add_image, _add_product_API } from "../../../DAL/product/product";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Input,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { _list_category } from "../../../DAL/category/category";
import {
  _add_website_page,
  _get_list_template_configuration,
  _get_template_configuration,
  _update_website_page,
} from "../../../DAL/website_setting/about_us_setting";
import GeneralCkeditor from "../../../components/GeneralComponents/GeneralCkeditor";
import { s3baseUrl } from "../../../config/config";

function AddManageWebsite() {
  const navigate = useNavigate();
  const param = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [dataList, setDataList] = useState([]);
  const [pageDetail, setPageDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [inputs, setInputs] = useState({
    page_title: "",
    meta_title: "",
    meta_keyword: "",
    choose_template: "",
    status: true,
    show_in: "both",
    page_alias_url: "",
  });

  const [templateInputs, setTemplateInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangePage = (event, i) => {
    const name = event.target.name;
    const value = event.target.value;
    setTemplateInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeTemplate = (e) => {
    const { name, value } = e.target;
    let find_id = dataList.find((temp) => temp._id == value);
    if (find_id) {
      setInputs((values) => ({
        ...values,
        [name]: value,
        ["meta_keyword"]: find_id?.template_name,
        ["page_title"]: find_id?.template_name,
        ["meta_title"]: find_id?.template_name,
        ["page_alias_url"]: find_id?.template_slug,
      }));
      setPageDetail(find_id?.template_attributes_info);
    }
  };

  const get_template_configuration = async () => {
    const response = await _get_template_configuration(param.id);
    if (response.code === 200) {
      let data = response?.website_page;
      if (data) {
        setPageDetail(data?.template?.template_attributes_info);
        setInputs({
          ...inputs,
          ...data,
          choose_template: data?.template?._id,
          meta_keyword: data?.meta_description,
          page_title: data?.website_page_title,
        });
        setTemplateInputs({
          ...data.page_details,
        });
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const get_template_list = async () => {
    const response = await _get_list_template_configuration();
    if (response.code === 200) {
      setDataList(response?.template_configuration);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleImageExtensions = (menu) => {
    let val = menu?.map((el) => {
      return '"' + el.substring(1).toLowerCase() + '"' + ",";
    });
    let unique = val?.filter((item, i, ar) => ar.indexOf(item) === i);
    return unique;
  };

  const fileChangedHandler = async (e, i) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", i.width);
    formData.append("height", i.height);
    let allowedImageExtension = i.image_extension;

    let uploadedImageExtension = e.target.files[0]?.type?.split("/")[1];
    let extension_matched = allowedImageExtension.includes(
      "." + uploadedImageExtension
    );
    if (extension_matched) {
      const imageUpload = await _add_image(formData);
      if (imageUpload.code == 200) {
        setTemplateInputs({
          ...inputs,
          [e.target.name]: imageUpload.path,
        });
      } else {
        enqueueSnackbar(imageUpload.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar(
        `Upload images with mentioned ${handleImageExtensions(
          allowedImageExtension
        )} extension`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let postData = {
      website_page_title: inputs.page_title,
      meta_title: inputs.meta_title,
      meta_description: inputs.meta_keyword,
      template: inputs?.choose_template,
      page_details: {
        ...templateInputs,
      },
      show_in: inputs.show_in,
      status: inputs.status,
      page_alias_url: inputs.page_alias_url,
    };

    const result = param.id
      ? await _update_website_page(postData, param.id)
      : await _add_website_page(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_template_list();
    if (param.id) {
      get_template_configuration();
    }
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  console.log(inputs?.choose_template, "inputs?.choose_template");

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
          <div>
            <h3 className="ms-3 mb-0 mt-2">
              {param.id ? "Edit Page" : "Add Page"}
            </h3>
          </div>
        </div>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        enctype="multipart/form-data"
      >
        <div className="row mt-4">
          <div className="col-md-6 mb-4">
            <FormControl fullWidth sx={{ mb: 0 }} required>
              <InputLabel id="demo-simple-select-label">
                Choose Template
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="choose_template"
                disabled={param?.id ? true : false}
                value={inputs?.choose_template}
                label="Choose Template"
                onChange={handleChangeTemplate}
              >
                {dataList.map((item, index) => {
                  return (
                    <MenuItem value={item._id} key={index}>
                      {item.template_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6 mb-4">
            <TextField
              id="outlined-basic"
              label="Page Title"
              value={inputs.page_title}
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="page_title"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mb-4">
            <TextField
              id="outlined-basic"
              label="Meta Title"
              variant="outlined"
              value={inputs.meta_title}
              sx={{ width: "100%" }}
              required
              name="meta_title"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mb-4">
            <TextField
              id="outlined-basic"
              label="Meta Keyword"
              value={inputs.meta_keyword}
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="meta_keyword"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mb-4">
            <FormControl fullWidth sx={{ mb: 0 }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                required
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6 mb-4" required>
            <FormControl fullWidth sx={{ mb: 0 }}>
              <InputLabel id="demo-simple-select-label">Show In</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_in"
                value={inputs.show_in}
                label="Show In"
                onChange={handleChange}
              >
                <MenuItem value="header">Header</MenuItem>
                <MenuItem value="footer">Footer</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {pageDetail.length > 0 && (
          <div className="row">
            <h3 className="mb-4">Page Contant Setting</h3>
            {pageDetail.map((item, i) => {
              return (
                <>
                  {item.attribute_type == "input" ? (
                    <div className="col-md-6 mb-4">
                      <TextField
                        id="outlined-basic"
                        label={item.attribute_label}
                        value={
                          !templateInputs
                            ? inputs[item.attribute_db_name]
                            : templateInputs[item?.attribute_db_name]
                        }
                        variant="outlined"
                        type={item.attribute_db_type}
                        sx={{ width: "100%" }}
                        required={item.required}
                        name={item.attribute_db_name}
                        onChange={(e) => handleChangePage(e, i)}
                      />
                    </div>
                  ) : item.attribute_type == "file" ? (
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4 employe-profile">
                      <div className="row w-100 div-style ms-0 pt-0">
                        <div className="col-5">
                          <p>
                            {item.attribute_label}
                            {item.required == true ? " *" : ""}
                          </p>
                        </div>
                        <div className="col-3">
                          {templateInputs ? (
                            <img
                              src={
                                templateInputs[item?.attribute_db_name] == "" ||
                                templateInputs[item?.attribute_db_name] == null
                                  ? ""
                                  : s3baseUrl +
                                    templateInputs[item?.attribute_db_name]
                              }
                              height="70"
                            />
                          ) : inputs[item.attribute_db_name] !== undefined ? (
                            <img
                              src={s3baseUrl + inputs[item.attribute_db_name]}
                              height="70"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-4 text-end pt-2">
                          <label
                            htmlFor={`${item.attribute_db_name} div-style`}
                          >
                            <Input
                              accept="image/*"
                              id={item.attribute_db_name}
                              multiple
                              type="file"
                              sx={{ display: "none" }}
                              name={item.attribute_db_name}
                              onChange={(e) => fileChangedHandler(e, item)}
                            />
                            <Button
                              className="small-contained-button"
                              startIcon={<FileUploadIcon />}
                              component="span"
                            >
                              Upload
                            </Button>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : item.attribute_type == "editor" ? (
                    <div className="col-12 mb-4">
                      <p className="home_page_header">
                        {item.attribute_label} {item.required ? "*" : ""}
                      </p>
                      <GeneralCkeditor
                        setInputs={setTemplateInputs}
                        inputs={templateInputs}
                        required
                        name={item.attribute_db_name}
                        editorHeight={350}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
        )}
        <div className="col-12 mt-3 text-end">
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddManageWebsite;
