import {
  FormControl,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import {
  _add_product_variations_info,
  _delete_product_variations_info,
  _product_list_variations_info,
  _shipment_list,
  _update_product_variations_info,
} from "../../DAL/product/product";
import { useEffect } from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import SelectMui from "../../components/Products/Variations/SelectMui";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator/ClassNameGenerator";
import closeFill from "@iconify/icons-eva/close-fill";
import { _update_shipment_received } from "../../DAL/Shipment/shipment_received/shipment_received";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function ProductVariations() {
  const { product_id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [variationsList, setVariationsList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState({});
  const [variationsData, setVariationsData] = useState([]);
  const [productCommonName, setProductCommonName] = useState("");
  const [opneDeadCount, setopneDeadCount] = useState(false);
  const [loadingData, setloadingData] = useState(false);
  const [sipmentData, setsipmentData] = useState([]);
  const [inputs, setInputs] = useState({
    unit_price: "",
    quantity: "",
    is_default_variation: true,
    dead_count: "",
    shipment_list: null,
    date: dayjs(new Date()),
    dead_type: "dead_in_store",
  });

  const handle_remove_values = () => {
    setInputs({
      ...inputs,
      unit_price: "",
      quantity: "",
      is_default_variation: true,
    });
    setSelectedAttributes([]);
    setSelectedVariation({});
    setFormType("ADD");
    setIsLoadingForm(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleEdit = (value) => {
    setInputs({
      ...inputs,
      ...value,
    });
    setFormType("EDIT");
    if (value.attributes_items.length > 0) {
      setSelectedAttributes(value.attributes_items);
    }
  };
  const shipmentList = async (value) => {
    setloadingData(true);
    let objectData = {
      shippments_items: value,
    };
    const result = await _shipment_list(objectData);
    if (result.code === 200) {
      let ship_data = result.shipment.map((item) => {
        let shipmentTitle = "N/A";
        if (item.shipment_title) {
          shipmentTitle = item.shipment_title;
        }

        let shipmentDate = "N/A";
        if (item.shippment_date) {
          shipmentDate = item.shippment_date;
        }

        let Shipment_value = shipmentTitle + " | " + "(" + shipmentDate + ")";

        return {
          ...item,
          Shipment_value,
        };
      });
      setsipmentData(ship_data);

      if (ship_data.length > 0) {
        setInputs({
          ...inputs,
          shipment_list: ship_data[0],
        });
      }

      // setInputs((values) => ({
      //   ...values,
      //   ["dead_count"]: result.shipment[0].order_shipment_item[0].dead_count,
      // }));
      setloadingData(false);
    } else {
      setloadingData(false);
    }
  };
  const handleUPdateDeadCount = (value) => {
    shipmentList(value.shippments_items);
    setopneDeadCount(true);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeShipment = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeAttributes = (attribute, item) => {
    // Making Answer Object
    let newValue = {
      item_name: item.item_name,
      item_id: item._id,
      attribute_id: attribute._id,
      attribute_name: attribute.attribute_name,
    };

    if (selectedAttributes.length > 0) {
      let is_find = selectedAttributes.find(
        (obj) => obj.attribute_id == attribute._id
      );
      if (!is_find) {
        setSelectedAttributes((oldArray) => [...oldArray, newValue]);
      } else {
        setSelectedAttributes((prev) => {
          return prev.map((item) => {
            if (item.attribute_id == attribute._id) {
              return newValue;
            } else {
              return item;
            }
          });
        });
      }
    } else {
      setSelectedAttributes([newValue]);
    }
  };

  const get_variations_info = async () => {
    const response = await _product_list_variations_info(product_id);

    if (response.code === 200) {
      let data = response.data.variation.map((variation) => {
        let variationName1 = "";
        let variationName2 = "";
        if (variation.attributes_items[0]) {
          variationName1 =
            variation.attributes_items[0]?.attribute_name +
              " | " +
              variation.attributes_items[0]?.item_name || "";
        }
        if (variation.attributes_items[1]) {
          variationName2 = variation.attributes_items[1]?.attribute_name
            ? variation.attributes_items[1]?.attribute_name +
              " | " +
              variation.attributes_items[1]?.item_name
            : "";
        }
        return {
          ...variation,
          attribute1: variationName1,
          attribute2: variationName2,
          MENU_OPTIONS: handleMenu(variation),
        };
      });
      setVariationsList(data);

      setAttributesList(response.data.attributes);
      setProductCommonName(
        response.product.common_name + " " + response.product.scientific_name
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoadingForm(true);
    e.preventDefault();
    // setIsLoading(true);

    const objectData = {
      unit_price: inputs.unit_price,
      quantity: inputs.quantity,
      attributes_items: selectedAttributes,
      is_default_variation: inputs.is_default_variation,
    };
    if (formType == "EDIT") {
      objectData.product_id = product_id;
      objectData.variations_id = inputs._id;
    }
    // setIsLoading(true);

    const result =
      formType == "ADD"
        ? await _add_product_variations_info(objectData, product_id)
        : await _update_product_variations_info(objectData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      get_variations_info();
      handle_remove_values();
      // setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      // setIsLoading(false);
    }
  };
  const handleSubmitShipment = async (e) => {
    e.preventDefault();
    if (!inputs.shipment_list) {
      enqueueSnackbar("Please select a shipment.", { variant: "error" });
      return;
    }
    const deadCount = Number(inputs.dead_count);
    if (deadCount <= 0) {
      enqueueSnackbar("Dead Count should be greater than 0.", {
        variant: "error",
      });
      return;
    }

    if (!inputs.date) {
      enqueueSnackbar("Please select a Date.", {
        variant: "error",
      });
      return;
    }
    setIsLoadingForm(true);
    let landed =
      inputs.dead_count ==
      inputs.shipment_list.order_shipment_item[0].total_quantity
        ? 0
        : (
            parseFloat(inputs.shipment_list.order_shipment_item[0].total_cost) /
            (parseFloat(
              inputs.shipment_list.order_shipment_item[0].total_quantity
            ) -
              parseFloat(inputs.dead_count))
          ).toFixed(2);
    const unitCost =
      inputs.dead_count ==
      inputs.shipment_list.order_shipment_item[0].total_quantity
        ? 0
        : (
            parseFloat(
              inputs.shipment_list.order_shipment_item[0].per_unit_cost
            ) *
              (parseFloat(
                inputs.shipment_list.order_shipment_item[0].total_quantity
              ) -
                parseFloat(inputs.dead_count)) +
            (parseFloat(
              inputs.shipment_list.order_shipment_item[0].total_cost
            ) /
              (parseFloat(
                inputs.shipment_list.order_shipment_item[0].total_quantity
              ) -
                parseFloat(inputs.dead_count))) *
              parseFloat(
                inputs.shipment_list.order_shipment_item[0].total_quantity
              )
          ).toFixed(2);
    const refund = (
      parseFloat(inputs.dead_count) *
      parseFloat(inputs.shipment_list.order_shipment_item[0].per_unit_cost)
    ).toFixed(2);
    const objectData = {
      product_id: inputs.shipment_list.order_shipment_item[0].product_id,
      shipment_item_id: inputs.shipment_list.order_shipment_item[0]._id,
      landed_cost: landed,
      dead_count: inputs.dead_count,
      cost_per_item: unitCost,
      live_count:
        inputs.shipment_list.order_shipment_item[0].total_quantity -
        inputs.dead_count,
      refund_amount: refund,
      date: inputs.date,
      dead_type: inputs.dead_type,
    };
    if (
      inputs.dead_count <=
        inputs.shipment_list.order_shipment_item[0].total_quantity ||
      (inputs.dead_count == 0 && inputs.dead_count != "")
    ) {
      const result = await _update_shipment_received(
        objectData,
        inputs.shipment_list._id
      );
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        get_variations_info();
        setInputs({
          ...inputs,
          dead_count: "",
          shipment_list: null,
          date: dayjs(new Date()),
          dead_type: "dead_in_store",
        });
        setIsLoadingForm(false);
        setopneDeadCount(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingForm(false);
      }
    } else {
      if (landed == "") {
        enqueueSnackbar("Dead quantity can not be  empty ", {
          variant: "error",
        });
        setIsLoadingForm(false);
      } else {
        enqueueSnackbar(
          "Dead quantity can not be greater than total quantity ",
          { variant: "error" }
        );
        setIsLoadingForm(false);
      }
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    let objectData = {
      product_id: product_id,
      variations_id: selectedVariation._id,
    };
    const result = await _delete_product_variations_info(objectData);
    if (result.code === 200) {
      const data = variationsList.filter(
        (item) => item._id !== selectedVariation._id
      );
      setVariationsList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDateChange = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: dayjs(value.$d),
      };
    });
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      alignRight: false,
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "attribute1", label: "Attribute 1", alignRight: false },
    { id: "attribute2", label: "Attribute 2", alignRight: false },
    { id: "sold_quantity", label: "Sold Quantity", alignRight: false },
    {
      id: "available_quantity",
      label: "Available Quantity",
      alignRight: false,
    },
    { id: "unit_price", label: "Sale Price", alignRight: false },
  ];

  const handleHistory = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/products/product-variations/dead-history/${value._id}`, {
      state: { reviewValues: value },
    });
  };

  let MENU_OPTIONS = [];

  const handleMenu = (row) => {
    MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
    ];
    if (row.shippments_items.length > 0) {
      MENU_OPTIONS.push({
        label: "Add dead count",
        icon: "akar-icons:edit",
        handleClick: handleUPdateDeadCount,
      });
    }
    MENU_OPTIONS.push({
      label: "Dead Count History",
      icon: "pajamas:eye",
      handleClick: handleHistory,
    });
    return MENU_OPTIONS;
  };

  const handleclose = () => {
    setopneDeadCount(false);
  };

  useEffect(() => {
    get_variations_info();
  }, []);

  useEffect(() => {
    setVariationsData(variationsList);
  }, [variationsList]);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <IconButton onClick={handleBack} className="icon-button">
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
            <span className="page_title">{productCommonName}</span>
          </div>
          <div className="col-6 my-3">
            <h3>Product Variations</h3>
          </div>
        </div>
        {attributesList.length > 0 && formType != "ADD" && (
          <div className="row mx-1">
            <div className="card mb-4" id="add_new">
              <Typography
                className="typography-font-heading"
                sx={{ mt: "10px", ml: "15px" }}
              >
                {/* {`${formType == "ADD" ? "Add" : "Edit"} Variation`} */}
                Edit Variation
              </Typography>
              <form
                className="new-variation-form"
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <div className="row align-items-center">
                  {attributesList.length > 0 &&
                    attributesList.map((attribute, index) => {
                      //Getting Selected Item
                      let item_id = selectedAttributes.find(
                        (item) => item.attribute_id == attribute._id
                      )?.item_id;

                      return (
                        <div
                          className={
                            attributesList.length >= 1
                              ? "col-lg-6 col-md-6 mt-1"
                              : `col-12 col-md-6 mt-1`
                          }
                          key={index}
                        >
                          <SelectMui
                            attribute={attribute}
                            item_id={item_id}
                            handleChangeAttributes={handleChangeAttributes}
                          />
                        </div>
                      );
                    })}
                  <div
                    className={
                      attributesList.length > 1
                        ? `col-lg-6 col-md-6 mt-3`
                        : `col-lg-6 col-md-6 `
                    }
                  >
                    <TextField
                      value={inputs.unit_price}
                      name="unit_price"
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      label="Sale Price"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                {/* <div className="col-12 col-md-4 mt-3">
                    <TextField
                      value={inputs.quantity}
                      name="quantity"
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      label="Quantity"
                      className="form-control"
                      required
                    />
                  </div> */}
                {/* <div className="col-12 col-md-6 mt-3">
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Default Variation
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="is_default_variation"
                        value={inputs.is_default_variation}
                        required
                        label="Default Variation"
                        onChange={handleChange}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </div> */}

                <div className="my-3 text-end">
                  <button
                    className="small-contained-button"
                    type="submit"
                    disabled={isLoadingForm}
                  >
                    {isLoadingForm
                      ? formType === "ADD"
                        ? "Submitting..."
                        : "Updating..."
                      : formType === "ADD"
                      ? "Submit"
                      : "Update"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {opneDeadCount &&
          (loadingData == true ? (
            <span></span>
          ) : (
            <div className="row mx-1">
              <div className="card mb-4" id="add_new">
                <Typography
                  className="typography-font-heading"
                  sx={{ mt: "10px", ml: "15px" }}
                >
                  Add Dead Count
                </Typography>
                <div data-html2canvas-ignore="true" className="cross">
                  <IconButton onClick={handleclose} className="icon-button">
                    <Icon icon={closeFill} width={20} height={20} />
                  </IconButton>
                </div>
                <form
                  className="new-variation-form"
                  onSubmit={handleSubmitShipment}
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6 mt-3">
                      <FormControl fullWidth sx={{ mb: 2 }} required>
                        <InputLabel id="demo-simple-select-label">
                          Shipment List
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="shipment_list"
                          value={inputs.shipment_list}
                          label="Shipment List"
                          onChange={handleChangeShipment}
                        >
                          {sipmentData.map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item.Shipment_value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-6 col-md-6 mt-3">
                      <TextField
                        value={inputs.dead_count}
                        name="dead_count"
                        onChange={handleChangeShipment}
                        fullWidth
                        type="number"
                        label="Dead count"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label">
                          Dead Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="dead_type"
                          value={inputs.dead_type}
                          label="Dead Type"
                          onChange={handleChange}
                        >
                          <MenuItem value="dead_in_store">
                            Dead In Store
                          </MenuItem>
                          <MenuItem value="dead_on_arrival">
                            Dead On Arrival
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Dead Date *"
                          format="DD-MM-YYYY"
                          className="form-control"
                          name="date"
                          required
                          value={inputs.date}
                          onChange={(e) => {
                            handleDateChange("date", e);
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="my-3 text-end">
                    <button
                      className="small-contained-button"
                      type="submit"
                      disabled={isLoadingForm}
                    >
                      {isLoadingForm
                        ? formType === "ADD"
                          ? "Submitting..."
                          : "Updating..."
                        : formType === "ADD"
                        ? "Submit"
                        : "Update"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ))}

        <div className="row mx-1 card">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={variationsData}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
          />
        </div>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Variation?"}
        handleAgree={handleDelete}
      />
    </>
  );
}

export default ProductVariations;
