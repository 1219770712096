import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import {
  Dashboard,
  Login,
  ForgotPassword,
  Categories,
  Challenges,
  CustomersList,
  Orders,
  ShipmentPrices,
  Tags,
  EditProfile,
  Stock,
  WebsiteFaqs,
  EditWebsiteFaqs,
  AddWebsiteFaqs,
  Quotation,
  QuotationCategory,
  Vendors,
  AttributeVarients,
  AddAttribute,
  Shipments,
  SalesReport,
  AddShipment,
  EditShipment,
  ProductVariations,
  ShipmentItems,
  ShipmentTransit,
  ShipmentReceived,
  CustomerOrders,
  CreateOrders,
  OrderPrepare,
  OrderDetail,
} from "./pages";
import GeneralSetting from "./pages/website_setting/GeneralSetting";
import AddEmployees from "./pages/Employes/AddEmployees";
import EditEmployees from "./pages/Employes/EditEmployees";
import Employes from "./pages/Employes/Employes";
import AddManageWebsite from "./pages/ManageWebsite/Pages/AddManageWebsite";
import FaqList from "./pages/ManageWebsite/Pages/Faqs/FaqList";
import ManageWebsiteList from "./pages/ManageWebsite/Pages/ManageWebsiteLst";
import AddProducts from "./pages/Products/AddProducts";
import EditProduct from "./pages/Products/EditProduct";
import ProductReview from "./pages/Products/ProductReview/ProductReview";
import Products from "./pages/Products/ProductsList";
import Tasks from "./pages/Tasks/Tasks";
import SocialMediaSetting from "./pages/website_setting/SocialMediaSetting";
import HomeSetting from "./pages/website_setting/HomeSetting";
import AboutUsSetting from "./pages/website_setting/AboutUsSetting";
import WhatsOnStockSetting from "./pages/website_setting/WhatsOnStockSetting";
import CheckoutSetting from "./pages/website_setting/CheckoutSetting";
import ContactUsSetting from "./pages/website_setting/ContactUsSetting";
import ExtraExpenseList from "./pages/ExtraExpenses/ExtraExpenseList";
import AddExtraExpense from "./pages/ExtraExpenses/AddExtraExpense";
import EditExtraExpense from "./pages/ExtraExpenses/EditExtraExpense";
import ProductDetailSetting from "./pages/website_setting/ProductDetailSetting";
import PaymentSetting from "./pages/website_setting/PaymentSetting";
import InformationSetting from "./pages/website_setting/InformationSetting";
import DeadHistory from "./pages/Products/DeadHistory";

// ----------------------------------------------------------------------

const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};

export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* Dashboard */}
        <Route path="/dashboard" element={<Dashboard />} />
        {/* Categories */}
        <Route path="/categories" element={<Categories />} />
        {/* Tags */}
        <Route path="/tags" element={<Tags />} />
        {/* Challenges */}
        <Route path="/challenges" element={<Challenges />} />
        {/* Quotation */}
        <Route path="/quotation" element={<Quotation />} />
        {/* Quotation Category */}
        <Route path="/quotation-category" element={<QuotationCategory />} />
        {/* Authors */}
        <Route path="/vendors" element={<Vendors />} />
        {/* Attribute Varients */}
        <Route path="/attribute-variants" element={<AttributeVarients />} />
        <Route
          path="/attribute-variants/add-attribute-variants"
          element={<AddAttribute />}
        />
        <Route
          path="/attribute-variants/edit-attribute-variants/:page_slug"
          element={<AddAttribute />}
        />
        {/* Products */}
        <Route path="/products" element={<Products />} />
        <Route
          path="/products/dead-history/:id"
          element={<DeadHistory type="products" />}
        />
        <Route path="/products/add-product" element={<AddProducts />} />
        <Route path="/products/edit-product/:id" element={<EditProduct />} />
        <Route path="/products/reviews/:id" element={<ProductReview />} />
        <Route path="/products/reviews/:id" element={<ProductReview />} />
        {/* User profile */}
        <Route
          path="/products/product-variations/:product_id"
          element={<ProductVariations />}
        />{" "}
        <Route
          path="/products/product-variations/dead-history/:id"
          element={<DeadHistory type="variations" />}
        />{" "}
        {/* Customers */}
        <Route path="/customers" element={<CustomersList />} />
        <Route path="/customers/customer-orders" element={<CustomerOrders />} />
        {/* Employee */}
        <Route path="/employee" element={<Employes />} />
        <Route path="/employee/add-employee" element={<AddEmployees />} />
        <Route path="/employee/Edit-employee/:id" element={<EditEmployees />} />
        {/* shipment */}
        <Route path="/shipment" element={<Shipments />} />
        <Route
          path="/shipment/dead-history/:id"
          element={<DeadHistory type="shipment" />}
        />
        <Route path="/shipment/add-shipment" element={<AddShipment />} />
        <Route path="/shipment/edit-shipment/:id" element={<EditShipment />} />
        <Route
          path="/shipment/shipment-items/:shipment_id"
          element={<ShipmentItems />}
        />
        <Route
          path="/shipment/shipment-transit/:shipment_id"
          element={<ShipmentTransit />}
        />
        <Route
          path="/shipment/shipment-received/:shipment_id"
          element={<ShipmentReceived />}
        />
        {/* Website Settings */}
        <Route path="/website-pages" element={<ManageWebsiteList />} />
        <Route path="/website-pages/add-page" element={<AddManageWebsite />} />
        <Route
          path="/website-pages/edit-page/:id"
          element={<AddManageWebsite />}
        />
        <Route path="/website-pages/faq/" element={<FaqList />} />
        {/* Task */}
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/tasks/:id" element={<Tasks />} />
        {/* Extra Expense */}
        <Route path="/extra-expenses" element={<ExtraExpenseList />} />
        <Route
          path="/extra-expenses/add-expense"
          element={<AddExtraExpense />}
        />
        <Route
          path="/extra-expenses/edit-expense/:id"
          element={<EditExtraExpense />}
        />
        {/* Orders */}
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/create-order" element={<CreateOrders />} />
        <Route path="/orders/order-prepare/:id" element={<OrderPrepare />} />
        <Route path="/orders/order-detail/:id" element={<OrderDetail />} />
        {/* Sales */}
        <Route path="/sales-report" element={<SalesReport />} />
        {/* Stock */}
        <Route path="/stock" element={<Stock />} />
        {/* WebsiteFaqs */}
        <Route path="/website-faqs" element={<WebsiteFaqs />} />
        <Route
          path="/website-faqs/add-website-faqs"
          element={<AddWebsiteFaqs />}
        />
        <Route
          path="/website-faqs/edit-website-faqs/:id"
          element={<EditWebsiteFaqs />}
        />
        {/* ShipmentPrices */}
        <Route path="/shipment-prices" element={<ShipmentPrices />} />
        {/* Website Settings */}
        <Route path="/general-setting" element={<GeneralSetting />} />
        <Route path="/social-media-setting" element={<SocialMediaSetting />} />
        <Route path="/home-setting" element={<HomeSetting />} />
        <Route path="/about-us-setting" element={<AboutUsSetting />} />
        <Route path="/informative-setting" element={<InformationSetting />} />
        <Route path="/checkout-setting" element={<CheckoutSetting />} />
        <Route path="/contact-us-setting" element={<ContactUsSetting />} />
        <Route path="/payment-setting" element={<PaymentSetting />} />
        <Route
          path="/whats-on-stock-setting"
          element={<WhatsOnStockSetting />}
        />
        <Route
          path="/product-detail-setting"
          element={<ProductDetailSetting />}
        />
      </Route>

      {/* Login */}
      <Route path="/login" element={<Login />} />

      {/* ForgotPassword */}
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
}
