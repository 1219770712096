import { invokeApi } from "../../bl_libs/invokeApi";

export const _add_product = async (data) => {
  const requestObj = {
    path: `api/product/add_product`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _list_product = async (data) => {
  const requestObj = {
    path: `api/product/list_product`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _detail_product = async (id) => {
  const requestObj = {
    path: `api/product/detail_product/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_product = async (id) => {
  const requestObj = {
    path: `api/product/delete_product/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _edit_product = async (postData, _id) => {
  const requestObj = {
    path: `api/product/edit_product/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _list_product_review = async (data) => {
  const requestObj = {
    path: `api/product_review/list_product_review`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_reviews_with_product_id = async (id) => {
  const requestObj = {
    path: `api/product_review/list_reviews_with_product_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const filter_shipment_history = async (page, limmit, data) => {
  const requestObj = {
    path: `api/shipment_received/filter_shipment_history?page=${page}&limit=${limmit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_product_review = async (id) => {
  const requestObj = {
    path: `api/product_review/delete_product_review/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_product_review = async (data) => {
  const requestObj = {
    path: `api/product_review/add_product_review`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _edit_product_review = async (postData, _id) => {
  const requestObj = {
    path: `api/product_review/edit_product_review/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
//....................... product variations

export const _product_list_variations_info = async (_id) => {
  const requestObj = {
    path: `api/product/list_variations_info/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: postData,
  };
  return invokeApi(requestObj);
};

export const _add_product_variations_info = async (postData, _id) => {
  const requestObj = {
    path: `api/product/new_product_variation_info/${_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _shipment_list = async (postData) => {
  const requestObj = {
    path: `api/shipment/list_shippment_for_update_variation`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _update_product_variations_info = async (postData) => {
  const requestObj = {
    path: `api/product/edit_variations_info`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _delete_product_variations_info = async (postData) => {
  const requestObj = {
    path: `api/product/delete_variations_info`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _products_list_pagination = async (page, limit, search) => {
  const requestObj = {
    path: `api/product/list_product_with_pagination?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _list_child_categories = async (id) => {
  const requestObj = {
    path: `api/category/list_child_categories_with_parent_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_image = async (postData) => {
  const requestObj = {
    path: `api/app_api/upload_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _del_image = async (postData) => {
  const requestObj = {
    path: `api/init/delete_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
