import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  _add_shipment_transit,
  _delete_shipment_transit,
  _list_shipment_transit_by_shipment,
  _update_shipment_transit,
} from "../../DAL/Shipment/shipment_transit/shipment_transit";
import { _list_shipment } from "../../DAL/Shipment/shipment";

import { useEffect } from "react";
import { _get_extra_expanses_list } from "../../DAL/extra_expense/extra_expense";
import { useSnackbar } from "notistack";
import CustomConfirmation from "../../components/GeneralComponents/CustomConfirmation";
import { useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "expense", label: "Expense", alignRight: false },
  { id: "expeneAmount", label: "Amount", alignRight: false },
];

export default function ShipmentTransit() {
  const { shipment_id } = useParams();
  const add_new_ref = useRef(null);
  const [shipmentTransit, setShipmentTransit] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalExpense, setTotalExpense] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [expenseList, setExpenseList] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  // const [selectedVendor, setSelectedVendor] = useState(null);
  const [formType, setFormType] = useState("ADD");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    title: "",
    amount: "",
  });
  // const total_expenses=

  const handleBack = () => {
    navigate(`/shipment`);
  };

  const handle_remove_values = () => {
    setInputs({
      title: "",
      amount: "",
    });
    setFormType("ADD");
    setSelectedExpense(null);
  };

  const get_shipment_transit_info = async () => {
    const response = await _list_shipment_transit_by_shipment(shipment_id);
    if (response.code === 200) {
      let data = response.shipment_transit.map((item) => {
        return {
          ...item,
          expense: item.title,
          expeneAmount: "$" + item.amount,
        };
      });
      setShipmentTransit(data);
      // get_shipment_list();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    setIsLoadingForm(true);
    e.preventDefault();

    const objectData = {
      expense_id: selectedExpense._id,

      title: selectedExpense.title,
      amount: inputs.amount,
    };

    if (formType == "ADD") {
      objectData.shipment_id = shipment_id;
      objectData.expense_id = selectedExpense._id;
    } else {
      objectData.shipment_transit_id = inputs._id;
      objectData.expense_id = selectedExpense._id;
    }

    const result =
      formType == "ADD"
        ? await _add_shipment_transit(objectData)
        : await _update_shipment_transit(objectData, shipment_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handle_remove_values();
      setIsLoadingForm(false);
      get_shipment_transit_info();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleEdit = (value) => {
    setInputs(value);
    let selecte_expense = expenseList.find(
      (expense) => expense._id == value.expense_id
    );

    setSelectedExpense(selecte_expense);
    setFormType("EDIT");

    setTimeout(() => {
      add_new_ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleAgreeDelete = (value) => {
    setSelectedItem(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    let objectData = {
      shipment_transit_id: selectedItem._id,
    };
    const result = await _delete_shipment_transit(objectData, shipment_id);
    if (result.code === 200) {
      const data = shipmentTransit.filter(
        (item) => item._id !== selectedItem._id
      );
      setShipmentTransit(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_expense_listing = async () => {
    setIsLoading(true);
    const result = await _get_extra_expanses_list();
    if (result.code === 200) {
      setExpenseList(result.extra_expanses);
      get_shipment_transit_info();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_expense_listing();
  }, []);

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <IconButton onClick={handleBack} className="icon-button">
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
          </div>
          <div className="col-6 my-3">
            <h3>Shipment Transit</h3>
          </div>
        </div>
        <div className="row mx-1">
          <div className="card mb-4" id="add_new">
            <Typography
              className="typography-font-heading"
              sx={{ mt: "10px", ml: "15px" }}
            >
              {`${formType == "ADD" ? "Add" : "Update"} Shipment Transit`}
            </Typography>
            <form
              className="new-variation-form"
              autoComplete="off"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="row align-items-center">
                <div className="col-xs-12 col-md-4 mt-3">
                  {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Expense
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-label"
                      name="expense"
                      value={selectedExpense?._id ? selectedExpense : ""}
                      label="expense"
                      onChange={(e) => {
                        setSelectedExpense(e.target.value);
                      }}
                      MenuProps={MenuProps}>
                      {expenseList.length > 0 &&
                        expenseList.map((expense, index) => {
                          return (
                            <MenuItem value={expense} key={index}>
                              {expense.title}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl> */}
                  <FormControl fullWidth>
                    <Autocomplete
                      id="expense-autocomplete"
                      options={expenseList}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) =>
                        option._id === value._id
                      }
                      value={selectedExpense}
                      onChange={(event, newValue) => {
                        setSelectedExpense(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Expense"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="col-xs-12 col-md-4 mt-3">
                  <TextField
                    value={inputs.amount}
                    name="amount"
                    onChange={handleChange}
                    fullWidth
                    // type="number"
                    label="Amount"
                    className="form-control"
                    required
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onKeyPress={(event) => {
                      if (!/[.,0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="my-3 text-end">
                <button
                  className="small-contained-button"
                  type="submit"
                  disabled={isLoadingForm}
                >
                  {isLoadingForm
                    ? formType === "ADD"
                      ? "Submitting..."
                      : "Updating..."
                    : formType === "ADD"
                    ? "Submit"
                    : "Update"}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="row mx-1 card">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={shipmentTransit}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
          />
          {/* <div>
            <p>{totalExpense}</p>
          </div> */}
        </div>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Shipment Transit?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
